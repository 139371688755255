module.exports = [{
      plugin: require('../../../themes/gatsby-theme-kn/gatsby-browser.js'),
      options: {"plugins":[],"storyblokOptions":{"storyblokToken":"ca2pc7HFyiG3ITd84j2iyQtt","storyblokVersion":"published","storyblokHomeSlug":"home","resolveRelations":["content_page.news_relations","content_page.gri_numbers","content_page.content_page_relations"]},"includedPageTypes":["content_page","article_page","home_page","index_page"],"clientPages":[],"includedCollectionTypes":["index_page"]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"König + Neurath Nachhaltigkeit","short_name":"K+N Nachhaltigkeit","start_url":"/","background_color":"#ffffff","theme_color":"#ff6e3d","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f4e78143752fa9d29c83cdb635436e9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
